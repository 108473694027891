import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/css/index.scss';
import './localization';
import { NeurosyncApp } from './App';
import reportWebVitals from './reportWebVitals';
import Logger from './util/Logger';
import Constants from './util/Constants';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<NeurosyncApp />);

if (!Constants.IS_PRODUCTION && false) {
	reportWebVitals(Logger.info);
}
