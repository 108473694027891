import { useLocation, useSearchParams } from 'react-router-dom';

export default function useCurrentRoute() {
	const location = useLocation();
	return { path: location.pathname };
}

export function useQueryParams(keys) {
	const [query] = useSearchParams();
	const obj = {};
	for (const key of keys) obj[key] = query.get(key);
	return obj;
}
