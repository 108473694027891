const Constants = {
	IS_PRODUCTION: process.env.NODE_ENV && process.env.NODE_ENV === 'production',
	IS_TEST: process.env.NODE_ENV && process.env.NODE_ENV === 'test'
};

export const Features = {
	REMOVE_MFA: !Constants.IS_PRODUCTION
};

export default Constants;
