import { useCallback, useEffect, useState } from 'react';
import Logger from '../util/Logger';
import { useFocusEffect } from './UseFocusEffect';
import DateUtil from '../util/DateUtil';
import AccountService from '../services/AccountService';
import { useQueryParams } from './UseCurrentRoute';
import KratosUtil from '../util/KratosUtil';

export const useSettingsFlow = (create) => {
	const params = useQueryParams(['flow']);
	const [flow, setFlow] = useState();
	const [loading, setLoading] = useState(true);
	const [groups, setGroups] = useState();

	useEffect(() => {
		if (params.flow) {
			AccountService.getSettingsFlow(params.flow)
				.then(handleFlow)
				.catch((error) => Logger.debug(error))
				.finally(() => setLoading(false));
		} else if (!create) {
			setLoading(false);
		} else {
			createSettingsFlow();
		}
	}, []);

	useFocusEffect(() => {
		if (flow && DateUtil.isBefore(flow?.expires_at, Date())) {
			if (create) createSettingsFlow();
		}
	}, [flow]);

	const createSettingsFlow = useCallback(() => {
		setLoading(true);

		AccountService.createSettingsFlow()
			.then(handleFlow)
			.catch((error) => Logger.debug(error))
			.finally(() => setLoading(false));
	}, []);

	const handleFlow = useCallback((flow) => {
		setFlow(flow);
		setGroups(KratosUtil.createUIGroups(flow));
	}, []);

	return { loading, flow, groups, setFlow: handleFlow };
};
