import React, { useState } from 'react';
import { AuthPage } from '../../components/Page';
import { withTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import Form from '../../components/form/Form';
import * as Yup from 'yup';
import TextFieldPassword from '../../components/form/TextFieldPassword';
import useFormValidation from '../../hoc/UseFormValidation';
import TextField from '../../components/form/TextField';
import Analytics from '../../analytics/Analytics';
import { AppEvent } from '../../analytics/TrackingEvent';
import AuthService from '../../services/AuthService';
import Notifications from '../../util/Notifications';
import NetworkUtil from '../../util/NetworkUtil';
import { Link, useNavigate } from 'react-router-dom';
import Links from '../../navigation/Links';
import { useAuthAction } from '../../hoc/UseAuthAction';
import { useSession } from '../../hoc/UseSession';
import Logger from '../../util/Logger';
import Button from '@mui/material/Button';
import { useLoginFlow } from '../../hoc/UseLoginFlow';
import KratosUtil from '../../util/KratosUtil';

const LoginPage = ({ t }) => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [validationErrors, setValidationErrors] = useState(null);
	const { flow } = useLoginFlow(true);
	const { action } = useAuthAction();
	const { user, setSession } = useSession();

	const { register, errors, handleSubmit } = useFormValidation({
		mode: 'onSubmit',
		validationErrors,
		initialValues: {
			identifier: user?.email || user?.identifier
		},
		schema: Yup.object({
			identifier: Yup.string().email(t('error_email_invalid')).required(t('error_email_required')),
			password: Yup.string().required(t('error_password_required'))
		})
	});

	const onSubmit = ({ identifier, password } = {}) => {
		setLoading(true);

		AuthService.login(flow, identifier, password)
			.then((data) => onLogin(identifier, data))
			.catch(async (err) => {
				if (NetworkUtil.isStatus(err, 422)) {
					await onOtpRequired(identifier);
				} else if (NetworkUtil.isStatus(err, 403) || NetworkUtil.isStatus(err, 401) || NetworkUtil.isStatus(err, 400)) {
					setValidationErrors([{ field: 'identifier', message: t('error_login_credentials') }]);
				} else Notifications.error(t('error_login_failed'));
			})
			.finally(() => setLoading(false));
	};

	const onLogin = (identifier, { session }) => {
		Analytics.log(AppEvent.Login(identifier, session));

		setSession(session);
		navigate(Links.ACCOUNT);
	};

	const onOtpRequired = async (identifier) => {
		try {
			const newFlow = await AuthService.updateLoginMethod(flow, 'aal2');

			const newGroups = KratosUtil.createUIGroups(newFlow);
			if (newGroups?.totp) {
				navigate(Links.Login.Otp(identifier, newFlow));
			} else if (newGroups?.lookup_secret) {
				navigate(Links.Login.BackupCode(identifier, newFlow));
			} else {
				navigate(Links.Login.Otp(identifier, newFlow));
			}
		} catch (e) {
			Logger.error(e);
			Notifications.error(t('error_login_failed'));
		}
	};

	return (
		<AuthPage
			title={t('sign_in')}
			description={action ? t(`auth_action_${action.type}`) : t('sign_in_description')}
			meta={{ title: t('sign_in') }}
			sx={{ alignItems: 'center', justifyContent: 'center' }}
		>
			<Form onSubmit={handleSubmit(onSubmit)} data-testid='loginForm' style={{ width: '100%' }}>
				<TextField
					data-testid='emailInput'
					{...register('identifier')}
					required={false}
					error={errors}
					fullWidth
					label={t('label_email')}
				/>
				<TextFieldPassword
					data-testid='passwordInput'
					{...register('password')}
					required={false}
					error={errors}
					fullWidth
					label={t('label_password')}
				/>

				<LoadingButton sx={{ mt: 1 }} size='large' fullWidth type='submit' loading={loading}>
					{t('sign_in')}
				</LoadingButton>
			</Form>

			<Link to={Links.Login.FORGOT_PASSWORD}>
				<Button variant='text' fullWidth sx={{ mt: 1 }}>
					{t('forgot_password')}
				</Button>
			</Link>
		</AuthPage>
	);
};

export default withTranslation()(LoginPage);
