import React, { useEffect, useRef, useState } from 'react';
import { AuthPage } from '../../components/Page';
import { withTranslation } from 'react-i18next';
import Form from '../../components/form/Form';
import * as Yup from 'yup';
import useFormValidation from '../../hoc/UseFormValidation';
import TextField from '../../components/form/TextField';
import Analytics from '../../analytics/Analytics';
import { AppEvent } from '../../analytics/TrackingEvent';
import AuthService from '../../services/AuthService';
import { LoadingButton } from '@mui/lab';
import Skeleton from '@mui/material/Skeleton';
import Logger from '../../util/Logger';
import { useAuthAction } from '../../hoc/UseAuthAction';
import { Chip, Typography } from '@mui/material';
import { useQueryParams } from '../../hoc/UseCurrentRoute';
import { ReactComponent as AccountIcon } from '../../assets/icons/account.svg';
import Links from '../../navigation/Links';
import { Link, useNavigate } from 'react-router-dom';
import { useSession } from '../../hoc/UseSession';
import { useLoginFlow } from '../../hoc/UseLoginFlow';
import Button from '@mui/material/Button';
import { DevicesOutlined, LockOutlined } from '@mui/icons-material';
import Styles from '../../assets/styles/Styles';
import NetworkUtil from '../../util/NetworkUtil';
import KratosUtil from '../../util/KratosUtil';

const VerifyPage = ({ t, backup }) => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [validationErrors, setValidationErrors] = useState(null);
	const { flow, groups, loading: loadingFlow } = useLoginFlow();
	const { action } = useAuthAction();
	const params = useQueryParams(['identity']);
	const { setSession } = useSession();
	const codeInputRef = useRef(null);

	const { register, errors, handleSubmit, resetValues } = useFormValidation({
		mode: 'onSubmit',
		validationErrors,
		schema: Yup.object({
			code: Yup.string().required(backup ? t('error_backup_code_required') : t('error_otp_required'))
		})
	});

	useEffect(() => {
		resetValues();
		codeInputRef?.current?.focus();
	}, [backup]);

	useEffect(() => {
		if (!backup && !groups?.totp) {
			if (groups?.lookup_secret) navigate(`${Links.Login.BACKUP_CODE}${window.location.search}`);
		}
	}, [groups]);

	const onSubmit = ({ code } = {}) => {
		setLoading(true);

		(backup ? AuthService.verifyOtpBackupCode(flow, code) : AuthService.verifyOtp(flow, code))
			.then(onVerified)
			.catch((error) => {
				if (NetworkUtil.isStatus(error, 400)) {
					setValidationErrors([
						{
							field: 'code',
							message: KratosUtil.getMessage(NetworkUtil.errorBody(error)) || t('error_invalid_code')
						}
					]);
				} else Logger.error(error);
			})
			.finally(() => setLoading(false));
	};

	const onVerified = (session) => {
		Analytics.log(AppEvent.Verify(params.identity, session));

		setSession(session);
		navigate(Links.ACCOUNT);
	};

	const onLoginClick = () => {
		AuthService.logout().catch(() => {});
		navigate(Links.LOGIN);
	};

	return (
		<AuthPage
			title={backup ? t('enter_backup_code') : t('verify_otp')}
			description={backup ? t('enter_backup_code_description') : t('verify_otp_description')}
			meta={{ title: t('verify') }}
			sx={{ alignItems: 'center', justifyContent: 'center' }}
		>
			{action && (
				<Typography variant='body2' mt={-2} mb={2}>
					{t('verify_your_account')} {t(`auth_action_${action?.type}`)}
				</Typography>
			)}

			{params.identity && (
				<Chip
					icon={<AccountIcon height={24} width={24} />}
					label={params.identity}
					sx={{ mt: -1.75, mb: 2 }}
					onClick={onLoginClick}
				/>
			)}

			<Form onSubmit={handleSubmit(onSubmit)} data-testid='otpForm' style={{ width: '100%' }}>
				<TextField
					inputRef={codeInputRef}
					data-testid='codeInput'
					{...register('code')}
					required={false}
					error={errors}
					autoFocus
					fullWidth
					inputProps={{ style: Styles.CodeInput }}
				/>

				{!loadingFlow && backup && !groups?.totp && (
					<Typography variant='body2' color='error' mb={1}>
						{t('error_totp_not_registered')}
					</Typography>
				)}

				<LoadingButton sx={{ mt: 1 }} size='large' fullWidth disabled={loadingFlow} type='submit' loading={loading}>
					{t('verify')}
				</LoadingButton>
			</Form>

			{loadingFlow ? (
				<Skeleton variant='rounded' width={160} height={42} sx={{ mt: 1 }} />
			) : !backup && groups?.lookup_secret ? (
				<Link to={`${Links.Login.BACKUP_CODE}${window.location.search}`}>
					<Button variant='text' fullWidth sx={{ mt: 1 }}>
						<Typography color='text.primary' mr='8px'>
							{t('use_backup_code_hint')}
						</Typography>
						<LockOutlined sx={{ mr: 0.25, mt: '-4px' }} /> {t('use_backup_code')}
					</Button>
				</Link>
			) : (
				backup &&
				groups?.totp && (
					<Link to={`..${window.location.search}`}>
						<Button variant='text' fullWidth sx={{ mt: 1 }} startIcon={<DevicesOutlined />}>
							{t('use_otp_code')}
						</Button>
					</Link>
				)
			)}
		</AuthPage>
	);
};

export default withTranslation()(VerifyPage);
