import React from 'react';
import { Navigate } from 'react-router-dom';
import Links from '../Links';
import useCurrentRoute from '../../hoc/UseCurrentRoute';
import FullscreenLayout from '../../components/layout/FullscreenLayout';
import { navigationTracker } from '../../analytics/NavigationTracker';
import { useAuthAction } from '../../hoc/UseAuthAction';

export const PublicRoute = ({ auth }) => {
	navigationTracker();
	const { action } = useAuthAction();
	const route = useCurrentRoute();

	return auth && !action ? <Navigate to={Links.ACCOUNT} /> : <FullscreenLayout location={route} />;
};
