import { Helmet } from 'react-helmet';
import { Box, Typography } from '@mui/material';
import Variables from '../assets/styles/Variables';
import { PageMessageView } from './MessageView';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { CircularLoader } from './Loader';
import React from 'react';
import { NeuroSyncLogoLarge } from './NeuroSyncBranding';

export default function Page({ meta, error, loading, sx, className = '', ...props }) {
	return (
		<Box
			className={`page-root flex flex-grow flex-column width-100 ${className}`}
			sx={[{ p: Variables.Padding.BodyRatio, position: 'relative' }, sx]}
		>
			<Helmet>
				<title>{meta?.title ? `${meta.title} | NeuroSync` : 'NeuroSync'}</title>
			</Helmet>

			{loading ? (
				<CircularLoader style={{ height: '100%', width: '100%', alignSelf: 'center', flex: 1 }} />
			) : error ? (
				<PageMessageView {...error} Icon={(props) => <ErrorOutlineOutlinedIcon {...props} />} />
			) : (
				props.children
			)}
		</Box>
	);
}

export const AuthPage = ({ meta, title, description, sx, loading, ...props }) => {
	return (
		<Page meta={meta} sx={{ alignItems: 'center', justifyContent: 'center', ...sx }} loading={loading}>
			<Box maxWidth={450} width='100%' display='flex' flexDirection='column' alignItems='center'>
				<NeuroSyncLogoLarge height={64} style={{ marginBottom: 36 }} />

				{title && (
					<Typography variant='h2' sx={{ mb: description ? 0 : 3, textAlign: 'center' }}>
						{title}
					</Typography>
				)}
				{description && (
					<Typography variant='body1' sx={{ mb: 3, textAlign: 'center' }}>
						{description}
					</Typography>
				)}

				{props.children}
			</Box>
		</Page>
	);
};
