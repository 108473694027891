const NetworkUtil = {
	status: (error) => error?.response?.status || error?.status,

	errorBody: (error) => error?.response?.data,

	isStatus: (error, status) => error?.response?.status === status || error?.status === status,

	getPageError(error, fullscreen = false) {
		return NetworkUtil.isStatus(error, 404)
			? { title: 404, message: 'Page Not Found', fullscreen }
			: { title: error?.response?.status || 500, message: 'Unknown Error Occurred', fullscreen };
	}
};

export default NetworkUtil;
