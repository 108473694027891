import React, { useState } from 'react';
import { AuthPage } from '../components/Page';
import { withTranslation } from 'react-i18next';
import Form from '../components/form/Form';
import * as Yup from 'yup';
import useFormValidation from '../hoc/UseFormValidation';
import { LoadingButton } from '@mui/lab';
import { useRecoveryFlow } from '../hoc/UseRecoveryFlow';
import { Chip } from '@mui/material';
import { useQueryParams } from '../hoc/UseCurrentRoute';
import { ReactComponent as AccountIcon } from '../assets/icons/account.svg';
import Links from '../navigation/Links';
import { useNavigate } from 'react-router-dom';
import FormValidations from '../util/FormValidations';
import TextFieldPassword from '../components/form/TextFieldPassword';

const ResetPasswordPage = ({ t }) => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const { flow } = useRecoveryFlow();
	const params = useQueryParams(['identity']);

	const { register, errors, handleSubmit, control } = useFormValidation({
		mode: 'onSubmit',
		schema: Yup.object({
			password: FormValidations.passwordValidator(t),
			confirmPassword: Yup.lazy(() => {
				return Yup.string()
					.required(t('error_password_not_matching'))
					.test('passwords-match', t('error_password_not_matching'), (value) => control._formValues.password === value);
			})
		})
	});

	const onSubmit = ({ code } = {}) => {
		setLoading(true);
	};

	return (
		<AuthPage
			title={t('reset_password')}
			description={t('reset_password_description')}
			meta={{ title: t('reset_password') }}
			sx={{ alignItems: 'center', justifyContent: 'center' }}
		>
			{params.identity && (
				<Chip
					icon={<AccountIcon height={24} width={24} />}
					label={params.identity}
					sx={{ mt: -1.75, mb: 2 }}
					onClick={() => navigate(Links.LOGIN)}
				/>
			)}

			<Form onSubmit={handleSubmit(onSubmit)} data-testid='otpForm' style={{ width: '100%' }}>
				<TextFieldPassword {...register('password')} error={errors} fullWidth newPassword label={t('label_password')} />
				<TextFieldPassword
					{...register('confirmPassword')}
					error={errors}
					fullWidth
					newPassword
					label={t('label_confirm_password')}
				/>

				<LoadingButton sx={{ mt: 1 }} size='large' fullWidth type='submit' loading={loading}>
					{t('confirm')}
				</LoadingButton>
			</Form>
		</AuthPage>
	);
};

export default withTranslation()(ResetPasswordPage);
