import { Box, Typography } from '@mui/material';
import Text from '../assets/styles/Typography';
import { Link } from 'react-router-dom';
import Links from '../navigation/Links';
import { NeuroSyncLogo } from './NeuroSyncBranding';

function MessageView({ icon, title, content, top, message, iconSize = 100, color = 'primary' }) {
	return (
		<Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' sx={{ m: 2, flex: 1 }}>
			{top}
			{icon && icon(iconSize)}
			{title && (
				<Typography {...Text.MessageTitle} color={color} sx={{ mt: 1 }}>
					{title}
				</Typography>
			)}
			<Box sx={{ mt: 2, width: '80%' }}>
				{content ? (
					content()
				) : (
					<Typography
						{...Text.Message}
						textAlign='center'
						color='text.light'
						style={{ maxWidth: 550, marginLeft: 'auto', marginRight: 'auto' }}
					>
						{message}
					</Typography>
				)}
			</Box>
		</Box>
	);
}

export function PageMessageView({ Icon, title, fullscreen, message, iconSize = 56 }) {
	return (
		<MessageView
			color='text.lightest'
			top={
				fullscreen && (
					<Link to={Links.HOME} style={{ marginBottom: 50 }}>
						<NeuroSyncLogo size='medium' />
					</Link>
				)
			}
			title={title}
			message={message}
			iconSize={iconSize}
			icon={
				Icon && ((size) => <Icon sx={(theme) => ({ width: size, height: size, fill: theme.palette.text.lightest })} />)
			}
		/>
	);
}

export default MessageView;
