import { Box } from '@mui/material';
import { forwardRef } from 'react';
import Styles from '../../assets/styles/Styles';
import Variables from '../../assets/styles/Variables';

function Form({ onSubmit, innerRef, sx = {}, ...props }) {
	return (
		<Box
			component='form'
			sx={[
				{
					'& .MuiTextField-root': Styles.Form.Field,
					'& .MuiFormControl-root': Styles.Form.Field,
					'& .MuiGrid-container': {
						width: `calc(100% + ${10}px)`,
						mt: 0,
						ml: -Variables.Padding.FormRatio
					},
					'& .MuiGrid-root .MuiGrid-item': {
						pt: 0,
						pl: Variables.Padding.FormRatio
					},
					'& .MuiGrid-root .MuiFormControl-root': {
						width: '100%'
					}
				},
				sx
			]}
			noValidate
			autoComplete='off'
			onSubmit={onSubmit}
			ref={innerRef}
			{...props}
		/>
	);
}

export default forwardRef((props, ref) => <Form {...props} innerRef={ref} />);
