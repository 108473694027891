export const RE_AUTH_AGE = 1;

export default {
	getCSRFToken: (session) => {
		const token = session?.ui?.nodes?.find((node) => node.attributes.name === 'csrf_token');
		return token?.attributes?.value;
	},

	handleAuthError: (error) => {
		return error?.response?.data?.error?.id === 'session_refresh_required';
	},

	createUIGroups: (flow) =>
		flow.ui.nodes.reduce((acc, object) => {
			const { group } = object;
			acc[group] = acc[group] || [];
			acc[group].push(object);
			return acc;
		}, {}),

	getMessage: (flow) => flow?.ui?.messages?.length ? flow.ui.messages[0].text : null
};
