import Menu from '@mui/material/Menu';
import * as React from 'react';
import { useCallback } from 'react';

export default function Dropdown({ menuItems, item }) {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);

	const handleClose = useCallback(() => setAnchorEl(null), []);

	return (
		<>
			{item({
				'aria-controls': 'basic-menu',
				'aria-haspopup': 'true',
				'aria-expanded': open ? 'true' : undefined,
				onClick: menuItems ? (event) => setAnchorEl(event.currentTarget) : undefined
			})}
			<Menu
				id='basic-menu'
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				onBlur={handleClose}
				MenuListProps={{ 'aria-labelledby': 'basic-button' }}
			>
				{menuItems && menuItems(handleClose)}
			</Menu>
		</>
	);
}
