import React, { useState } from 'react';
import { AuthPage } from '../components/Page';
import { withTranslation } from 'react-i18next';
import Form from '../components/form/Form';
import * as Yup from 'yup';
import useFormValidation from '../hoc/UseFormValidation';
import TextField from '../components/form/TextField';
import Analytics from '../analytics/Analytics';
import { AppEvent } from '../analytics/TrackingEvent';
import AuthService from '../services/AuthService';
import { LoadingButton } from '@mui/lab';
import Logger from '../util/Logger';
import { Chip } from '@mui/material';
import { ReactComponent as AccountIcon } from '../assets/icons/account.svg';
import Links from '../navigation/Links';
import { useNavigate } from 'react-router-dom';
import { useVerificationFlow } from '../hoc/UseVerificationFlow';

const AccountVerificationPage = ({ t, backup }) => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const { flow } = useVerificationFlow();

	const { register, errors, handleSubmit } = useFormValidation({
		mode: 'onSubmit',
		schema: Yup.object({
			code: Yup.string().required(t('error_verification_code_required'))
		})
	});

	const onSubmit = ({ otp } = {}) => {
		setLoading(true);

		AuthService.verifyAccountEmail(flow, otp)
			.then(onVerified)
			.catch((error) => {
				Logger.error(error);
				navigate(Links.ACCOUNT);
			})
			.finally(() => setLoading(false));
	};

	const onVerified = (flow) => {
		Analytics.log(AppEvent.AccountVerified());

		navigate(Links.ACCOUNT);
	};

	return (
		<AuthPage
			title={backup ? t('enter_backup_code') : t('verify_otp')}
			description={backup ? t('enter_backup_code_description') : t('verify_otp_description')}
			meta={{ title: t('verify') }}
			sx={{ alignItems: 'center', justifyContent: 'center' }}
		>
			{flow?.identity && (
				<Chip icon={<AccountIcon height={24} width={24} />} label={params.identity} sx={{ mt: -1.75, mb: 2 }} />
			)}

			<Form onSubmit={handleSubmit(onSubmit)} data-testid='codeForm' style={{ width: '100%' }}>
				<TextField
					data-testid='codeInput'
					{...register('code')}
					required={false}
					error={errors}
					autoFocus
					fullWidth
					inputProps={{ style: { fontSize: 32, textAlign: 'center' } }}
				/>

				<LoadingButton sx={{ mt: 1 }} size='large' fullWidth type='submit' loading={loading}>
					{t('verify')}
				</LoadingButton>
			</Form>
		</AuthPage>
	);
};

export default withTranslation()(AccountVerificationPage);
