import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Logger from '../util/Logger';
import { useCallback, useEffect } from 'react';
import Notifications from '../util/Notifications';

export default function useFormValidation({
	initialValues,
	validationErrors,
	announceErrors,
	schema,
	mode = 'onChange'
}) {
	const {
		handleSubmit,
		register,
		control,
		trigger,
		setError,
		reset,
		formState: { errors, ...state }
	} = useForm({
		defaultValues: initialValues,
		resolver: yupResolver(schema),
		mode: mode
	});

	const setErrors = useCallback(
		(validationErrors) => {
			try {
				if (!validationErrors || validationErrors.length === 0) return;
				for (const error of validationErrors) {
					setError(error.field, error);
				}
			} catch (e) {
				Logger.error('Failed to set forms errors', e);
			}
		},
		[setError]
	);

	useEffect(() => setErrors(validationErrors), [validationErrors]);
	if (announceErrors) {
		useEffect(() => {
			if (Object.keys(errors).length > 0) {
				for (const key in errors) {
					if (errors.hasOwnProperty(key)) {
						Notifications.error(errors[key].message);
					}
				}
			}
		}, [errors]);
	}

	const resetValues = useCallback(
		(obj) => {
			const value = {};
			const fields = Object.keys(control._fields);
			fields.forEach((field) => (value[field] = obj ? obj[field] : null));
			reset(value);
		},
		[control]
	);

	const validate = useCallback((name) => trigger(name, { shouldFocus: true }), [trigger]);
	const watch = useCallback((name) => useWatch({ control, name }), [control]);

	/**
	 * Custom register function that add `required` field as well
	 */
	const _register = useCallback(
		(name) => {
			const fieldProps = register(name);
			fieldProps.required = schema.describe()?.fields[name]?.tests?.some(({ name }) => name === 'required');
			return fieldProps;
		},
		[register, schema]
	);

	return {
		handleSubmit,
		register: _register,
		errors,
		setErrors,
		control,
		resetValues,
		setValues: reset,
		watch,
		validate,
		state
	};
}
