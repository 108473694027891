import toast from 'react-hot-toast';

const DEFAULT_DURATION = 3000;

const Notifications = {
	success: (message, { duration = DEFAULT_DURATION } = {}) => toast.success(message, { duration }),
	error: (message, { duration = DEFAULT_DURATION } = {}) => toast.error(message, { duration }),
	info: (message, { duration = DEFAULT_DURATION } = {}) => toast(message, { duration }),

	async: (
		promiseFn,
		{ loadingMsg = 'Please wait...', successMsg = 'Success', errorMsg = 'An error occurred' } = {}
	) => {
		return toast.promise(promiseFn, {
			loading: loadingMsg,
			success: successMsg,
			error: errorMsg
		});
	}
};

export default Notifications;
