import React, { useState } from 'react';
import { AuthPage } from '../components/Page';
import { withTranslation } from 'react-i18next';
import { LoadingButton } from '@mui/lab';
import Form from '../components/form/Form';
import * as Yup from 'yup';
import useFormValidation from '../hoc/UseFormValidation';
import TextField from '../components/form/TextField';
import AuthService from '../services/AuthService';
import Notifications from '../util/Notifications';
import { Link } from 'react-router-dom';
import Links from '../navigation/Links';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Text from '../assets/styles/Typography';
import { useRecoveryFlow } from '../hoc/UseRecoveryFlow';
import KratosUtil from '../util/KratosUtil';

const ForgotPasswordPage = ({ t }) => {
	const [loading, setLoading] = useState(false);
	const { flow, loading: loadingFlow } = useRecoveryFlow(true);
	const [success, setSuccess] = useState();

	const { register, errors, handleSubmit } = useFormValidation({
		mode: 'onSubmit',
		schema: Yup.object({
			email: Yup.string().email(t('error_email_invalid')).required(t('error_email_required'))
		})
	});

	const onSubmit = ({ email } = {}) => {
		setLoading(true);

		AuthService.sendRecoverPasswordEmail(flow, email)
			.then((flow) => setSuccess(KratosUtil.getMessage(flow) || t('msg_forgot_password_email_success')))
			.catch(() => Notifications.error(t('error_recover_password_failed')))
			.finally(() => setLoading(false));
	};

	return (
		<AuthPage
			title={t('recover_password')}
			description={t('recover_password_description')}
			meta={{ title: t('recover_password') }}
			sx={{ alignItems: 'center', justifyContent: 'center' }}
		>
			{success ? (
				<>
					<CheckCircleIcon sx={{ height: 56, width: 56, mb: 0.5 }} color='success' />
					<Typography {...Text.Message} textAlign='center' mb={2}>
						{success}
					</Typography>
				</>
			) : (
				<Form onSubmit={handleSubmit(onSubmit)} data-testid='forgotPasswordForm' style={{ width: '100%' }}>
					<TextField
						data-testid='emailInput'
						{...register('email')}
						required={false}
						error={errors}
						fullWidth
						label={t('label_email')}
					/>

					<LoadingButton sx={{ mt: 1 }} size='large' fullWidth type='submit' disabled={loadingFlow} loading={loading}>
						{t('send_recovery_link')}
					</LoadingButton>
				</Form>
			)}

			<Link to={Links.LOGIN}>
				<Button variant='text' fullWidth sx={{ mt: 1 }}>
					{t('login')}
				</Button>
			</Link>
		</AuthPage>
	);
};

export default withTranslation()(ForgotPasswordPage);
