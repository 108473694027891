import Text from './Typography';
import Typography, { DEFAULT_FONT_WEIGHT, typography } from './Typography';
import Variables from './Variables';
import Styles from './Styles';

const SIZE_SMALL = 'small';
const SIZE_MEDIUM = 'medium';
const SIZE_LARGE = 'large';

const DENSITY_DENSE = 'dense';
const DENSITY_NORMAL = 'regular';

const DEFAULT_BUTTON_VARIANT = 'contained';
const DEFAULT_INPUT_VARIANT = 'outlined';
const DEFAULT_CARD_VARIANT = 'outlined';

const COMPONENT_COLOR = 'primary';

export const OverrideStyles = {};

const ButtonStyles = {
	defaultProps: {
		size: SIZE_MEDIUM,
		disableElevation: true,
		variant: DEFAULT_BUTTON_VARIANT,
		color: 'secondary'
	},
	variants: [
		{
			props: { size: 'small' },
			style: {
				fontSize: typography.button.fontSizeSmall,
				padding: '6px 18px'
			}
		},
		{
			props: { size: 'medium' },
			style: {
				fontSize: typography.button.fontSizeMedium,
				padding: '8px 22px'
			}
		},
		{
			props: { size: 'large' },
			style: {
				fontSize: typography.button.fontSizeLarge,
				padding: '10px 28px'
			}
		}
	]
};

export const ComponentStyles = {
	MuiAppBar: {
		defaultProps: {
			elevation: 0
		},
		styleOverrides: {
			root: ({ theme }) => ({
				borderBottom: `1px solid ${theme.palette.divider}`,
				textColor: theme.palette.text.primary
			}),
			colorPrimary: ({ theme }) => ({
				backgroundColor: theme.palette.background.paper
			})
		}
	},
	MuiToolbar: {
		defaultProps: {
			variant: DENSITY_NORMAL
		},
		styleOverrides: {
			root: {
				paddingLeft: Variables.Padding.Body,
				paddingRight: Variables.Padding.Body,
				'@media (min-width: 600px)': {
					paddingLeft: Variables.Padding.Body,
					paddingRight: Variables.Padding.Body
				}
			}
		}
	},
	MuiButton: ButtonStyles,
	MuiLoadingButton: ButtonStyles,
	MuiIconButton: {
		defaultProps: {
			color: 'text'
		},
		styleOverrides: {
			root: {
				height: 'fit-content'
			}
		}
	},
	MuiButtonGroup: {
		defaultProps: {
			size: SIZE_MEDIUM
		}
	},
	MuiTextField: {
		defaultProps: {
			margin: DENSITY_DENSE,
			size: SIZE_MEDIUM,
			variant: DEFAULT_INPUT_VARIANT
		}
	},
	MuiInputBase: {
		defaultProps: {
			margin: DENSITY_DENSE,
			sx: { fontWeight: typography.input.fontWeight }
		}
	},
	MuiInputLabel: {
		defaultProps: {
			margin: DENSITY_DENSE
		},
		styleOverrides: {
			root: ({ theme }) => ({
				color: theme.palette.text.light
			})
		}
	},
	MuiFilledInput: {
		defaultProps: {
			margin: DENSITY_DENSE
		}
	},
	MuiOutlinedInput: {
		defaultProps: {
			margin: DENSITY_DENSE
		}
	},
	MuiFormControl: {
		defaultProps: {
			margin: DENSITY_DENSE
		}
	},
	MuiFormControlLabel: {
		styleOverrides: {
			root: {
				alignItems: 'flex-start',
				'& .MuiTypography-root': {
					marginTop: 8
				}
			}
		}
	},
	MuiSwitch: {
		defaultProps: {
			color: COMPONENT_COLOR
		}
	},
	MuiFormHelperText: {
		defaultProps: {
			margin: DENSITY_DENSE
		}
	},
	MuiFab: {
		defaultProps: {
			size: SIZE_LARGE
		},
		variants: [{ props: { size: SIZE_LARGE } }]
	},
	MuiTable: {
		defaultProps: {
			size: SIZE_SMALL
		}
	},
	MuiListItem: {
		defaultProps: {
			dense: true
		}
	},
	MuiMenuItem: {
		styleOverrides: {
			root: {
				minWidth: 140,
				dense: true
			}
		}
	},
	MuiDrawer: {
		styleOverrides: {
			paper: ({ theme }) => ({
				background: theme.palette.background.light
			})
		}
	},
	MuiTabPanel: {
		styleOverrides: {
			root: {
				flex: 1,
				position: 'relative',
				padding: 0,
				paddingTop: Variables.Padding.Body,

				'&[hidden]': {
					paddingTop: 0
				}
			}
		}
	},
	MuiCard: {
		defaultProps: {
			variant: DEFAULT_CARD_VARIANT
		},
		variants: [
			{
				props: { variant: 'elevation' },
				style: {
					boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px;'
				}
			},
			{
				props: { variant: 'outlined' },
				style: {
					backgroundColor: 'transparent'
				}
			}
		]
	},
	MuiCardContent: {
		styleOverrides: {
			root: {
				padding: Variables.Padding.Card,
				'&:last-child': {
					paddingBottom: Variables.Padding.Card
				}
			}
		}
	},
	MuiDataGrid: {
		styleOverrides: {
			root: {
				'& .MuiDataGrid-columnHeaderTitle': Typography.TableHeader
			}
		}
	},
	MuiDialog: {
		styleOverrides: {
			root: {
				...Styles.Dialog.MinWidth(450)
			}
		}
	},
	MuiDialogTitle: {
		styleOverrides: {
			root: {
				...Styles.Dialog.Content,
				...Text.DialogTitle
			}
		}
	},
	MuiDialogContent: {
		styleOverrides: {
			root: Styles.Dialog.Content
		}
	},
	MuiDialogActions: {
		styleOverrides: {
			root: {
				padding: `${Variables.Padding.Body * 1.5}px ${Variables.Padding.Body}px ${Variables.Padding.Body}px`
			}
		}
	},
	MuiChip: {
		styleOverrides: {
			root: {
				fontWeight: DEFAULT_FONT_WEIGHT + 100
			}
		}
	},
	MuiDivider: {
		defaultProps: ({ theme }) => ({
			color: theme.palette.divider
		}),
		styleOverrides: {
			root: ({ theme }) => ({
				borderColor: theme.palette.divider
			})
		}
	},
	MuiStack: {
		styleOverrides: {
			root: {
				height: 'fit-content'
			}
		}
	}
};
