import React from 'react';
import MuiAppBar from '@mui/material/AppBar';
import { Box, Toolbar } from '@mui/material';
import { styled } from '@mui/material/styles';
import { withTranslation } from 'react-i18next';
import ProfileMenu from '../ProfileMenu';
import Styles from '../../assets/styles/Styles';
import { NeuroSyncLogo } from '../NeuroSyncBranding';

export const AppBar = styled(MuiAppBar, {})(Styles.Navbar.AppBar);

const Navbar = ({ t }) => (
	<AppBar position='fixed' className='no-print'>
		<Toolbar>
			<Box display='flex' justifyContent='space-between' flex={1}>
				<Box display='flex' alignItems='center' justifyContent='flex-start'>
					<NeuroSyncLogo size='medium' />
				</Box>
				<Box display='flex' alignItems='center' justifyContent='flex-end'>
					<ProfileMenu t={t} />
				</Box>
			</Box>
		</Toolbar>
	</AppBar>
);

export default withTranslation()(Navbar);
