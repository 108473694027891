import Variables from './Variables';

const Styles = {
	Border: {
		borderBottom: 1,
		borderColor: 'divider',
		paddingBottom: '8px'
	},

	PageFab: {
		position: 'absolute',
		right: 0,
		bottom: 0
	},

	Navbar: {
		AppBar: ({ theme }) => ({
			zIndex: theme.zIndex.drawer - 1,
			transition: theme.transitions.create(['width', 'margin'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen
			}),
			...{
				width: '100%',
				transition: theme.transitions.create(['width', 'margin'], {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.enteringScreen
				})
			}
		})
	},

	Dialog: {
		MinWidth: (minWidth) => ({ '& .MuiDialog-paper': { minWidth } }),
		MinHeight: (minHeight) => ({ '& .MuiDialog-paper': { minHeight } }),
		Content: {
			padding: `${Variables.Padding.Body}px ${Variables.Padding.Body}px`,
			'& .MuiDialogActions-root': {
				padding: '36px 0 0'
			},
			'& .UserList': {
				maxHeight: 'calc(100vh - 410px)'
			}
		},
		FitContent: {
			PaperProps: { style: { maxWidth: 'fit-content' } }
		}
	},

	Form: {
		Field: { mb: Variables.Padding.FormRatio, mt: 0 },
		ListLabel: {}
	},

	CodeInput: {
		fontSize: 32,
		textAlign: 'center',
		letterSpacing: '10px'
	}
};

export default Styles;
