// noinspection JSUnusedAssignment

import { blue, green, grey } from '@mui/material/colors';
import { darken, lighten } from '@mui/material';

function rgba(hex, alpha) {
	const r = parseInt(hex.slice(1, 3), 16),
		g = parseInt(hex.slice(3, 5), 16),
		b = parseInt(hex.slice(5, 7), 16);

	return alpha ? `rgba(${r}, ${g}, ${b}, ${alpha / 100})` : `rgb(${r}, ${g}, ${b})`;
}

const PRIMARY_COLOR = '#0B223F';
const PRIMARY_COLOR_DARK = '#3874c0';
const ACCENT_COLOR = '#A91E23';

const Palette = {
	Primary: PRIMARY_COLOR,
	PrimaryLight: lighten(PRIMARY_COLOR, 0.94),
	Accent: ACCENT_COLOR,
	AccentLight: lighten(ACCENT_COLOR, 0.94),

	Black: '#000000',
	White: '#FFFFFF',
	Red: '#ff1744',
	Blue: blue['400'],
	Green: green['400'],
	DarkGrey: grey['800'],
	BlackGrey: '#121212',
	Grey: grey['600'],
	FogGrey: grey['200'],
	MediumGrey: grey['300']
};

const Base = {
	...Palette,

	Error: Palette.Red,
	Success: Palette.Green
};

const Light = {
	...Base,

	Light: Palette.White,
	Dark: Palette.Primary,

	Background: Palette.White,
	Selection: rgba(Palette.Primary, 12),

	Text: Palette.Primary,
	TextLight0: darken(Palette.FogGrey, 0.2),
	TextLight1: lighten(Palette.Primary, 0.385),
	TextLight2: lighten(Palette.Primary, 0.25),

	Divider: lighten(Palette.Black, 0.88)
};

const Dark = {
	...Base,

	Primary: PRIMARY_COLOR_DARK,
	PrimaryLight: lighten(darken(PRIMARY_COLOR_DARK, 0.94), 0.06),

	Light: Palette.FogGrey,
	Dark: Palette.BlackGrey,

	Background: Palette.BlackGrey,
	Selection: lighten(Palette.BlackGrey, 0.06),

	Text: Palette.MediumGrey,
	TextLight0: darken(Palette.FogGrey, 0.2),
	TextLight1: darken(Palette.FogGrey, 0.4),
	TextLight2: darken(Palette.FogGrey, 0.6),

	Divider: lighten(Palette.BlackGrey, 0.2)
};

const Colors = {
	...Base,
	Light,
	Dark,
	Quality: {
		Excellent: '#BEE1C5',
		Good: '#D2E17D',
		Average: '#F0C950',
		Fair: '#E9912A',
		Poor: '#E75C27',
		Bad: '#EA3D29'
	}
};

export default Colors;
