import { Navigate } from 'react-router-dom';
import { useQueryParams } from '../../hoc/UseCurrentRoute';
import Util from '../../util/Util';

const Redirect = ({ to, params, ...props }) => {
	if (!params) return <Navigate {...props} to={to} />;

	const query = useQueryParams(params);
	return <Navigate {...props} to={`${to}?${Util.queryString(query)}`} />;
};

export default Redirect;
