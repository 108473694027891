import * as React from 'react';
import { Component } from 'react';
import Logger from '../util/Logger';
import { PageMessageView } from './MessageView';
import WifiTetheringErrorRoundedOutlinedIcon from '@mui/icons-material/WifiTetheringErrorRoundedOutlined';

export const withErrorBoundary = (Element, page = true) => {
	return (
		<ErrorBoundary page={page}>
			<Element />
		</ErrorBoundary>
	);
};

export class ErrorBoundary extends Component {
	state = { error: null };

	static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { error };
	}

	componentDidUpdate(prevProps) {
		if (prevProps?.location?.pathname !== this.props?.location?.pathname) {
			this.setState({ error: null });
		}
	}

	componentDidCatch(error, info) {
		// Example "componentStack":
		//   in ComponentThatThrows (created by App)
		//   in ErrorBoundary (created by App)
		//   in div (created by App)
		//   in App
		Logger.error(error, info.componentStack);
	}

	render() {
		return !this.state.error
			? this.props.children
			: this.props.fallback || (
					<PageMessageView
						title='Something went wrong'
						message={this.state.error?.message}
						Icon={(props) => <WifiTetheringErrorRoundedOutlinedIcon {...props} />}
					/>
				);
	}
}
