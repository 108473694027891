import { useTheme } from '@mui/material';
import { AppTheme } from '../assets/styles/Theme';
import { ReactComponent as LogoLightLarge } from '../assets/images/neurosync_logo_light.svg';
import { ReactComponent as LogoDarkLarge } from '../assets/images/neurosync_logo_dark.svg';
import { ReactComponent as LogoLight } from '../assets/images/neurosync_logo_text_light.svg';
import { ReactComponent as LogoDark } from '../assets/images/neurosync_logo_text_dark.svg';
import { ReactComponent as IconLight } from '../assets/images/neurosync_icon_light.svg';
import { ReactComponent as IconDark } from '../assets/images/neurosync_icon_dark.svg';

export function NeuroSyncLogoLarge(props) {
	const theme = useTheme();
	return theme.palette.mode === AppTheme.DARK ? (
		<LogoLightLarge {...props} style={{ ...props.style, opacity: 0.9 }} />
	) : (
		<LogoDarkLarge {...props} />
	);
}

export function NeuroSyncLogo({ size = 'medium', ...props }) {
	const theme = useTheme();
	const height = size === 'small' ? 12 : undefined;
	const width = size === 'medium' ? 200 : undefined;

	return theme.palette.mode === AppTheme.DARK ? (
		<LogoLight height={height} width={width} {...props} style={{ ...props.style, opacity: 0.9 }} />
	) : (
		<LogoDark height={height} width={width} {...props} />
	);
}

export function NeuroSyncIcon(props) {
	const theme = useTheme();
	return theme.palette.mode === AppTheme.DARK ? (
		<IconLight {...props} style={{ ...props.style, opacity: 0.85 }} />
	) : (
		<IconDark {...props} />
	);
}
