import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from '../ErrorBoundary';

const FullscreenLayout = ({ location }) => {
	return (
		<main className='flex flex-grow' style={{ minWidth: 0 }}>
			<div id='main-container' className='flex flex-grow width-100'>
				<ErrorBoundary location={location} page>
					<Outlet />
				</ErrorBoundary>
			</div>
		</main>
	);
};

export default FullscreenLayout;
