import Navbar from './Navbar';
import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from '../ErrorBoundary';
import { Box } from '@mui/material';

const MainLayout = ({ location }) => (
	<>
		<Navbar />

		<main className='flex flex-grow flex-column' style={{ minWidth: 0 }}>
			<Box className='no-print' sx={(theme) => theme.mixins.toolbar} />
			<div id='main-container' className='flex flex-grow width-100'>
				<ErrorBoundary location={location} page>
					<Outlet />
				</ErrorBoundary>
			</div>
		</main>
	</>
);

export default MainLayout;
