import React from 'react';
import { CircularProgress, TextField as MuiTextField } from '@mui/material';
import _get from 'lodash/get';
import InputAdornment from '@mui/material/InputAdornment';

const TextField = ({ innerRef, loading, InputProps, error = {}, ...props }) => {
	const _error = _get(error, props.name);

	return (
		<MuiTextField
			ref={innerRef}
			{...props}
			error={!!_error}
			helperText={_error?.message || props.helperText}
			onKeyDown={
				props.type === 'number'
					? (event) => {
							if ((event.ctrlKey || event.metaKey) && event.key === 'a') return;
							if (event.key !== 'Backspace' && !/^[0-9]+$/.test(event.key)) event.preventDefault();
						}
					: props.onKeyDown
			}
			InputProps={
				loading
					? {
							...(InputProps || {}),
							endAdornment: (
								<InputAdornment position='end'>
									<CircularProgress color='info' size={24} />
								</InputAdornment>
							)
						}
					: InputProps
			}
		/>
	);
};

export default React.forwardRef((props, ref) => <TextField {...props} innerRef={ref} />);
