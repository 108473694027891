const FONT_BODY = '"Montserrat", "San Francisco", Helvetica, Roboto, Helvetica, Arial, sans-serif';
const FONT_HEADING = FONT_BODY;

export const RootVariables = {
	RootSpacing: 16,

	Padding: {
		BodyRatio: 1.5,
		CardRatio: 2,
		FormRatio: 0.625,
		SectionRatio: 2
	}
};

const Variables = {
	Font: {
		Body: FONT_BODY,
		Heading: FONT_HEADING,
		LogoSmall: '0.875rem'
	},

	NavbarOffset: 10,

	Padding: {
		...RootVariables.Padding,
		Root: RootVariables.RootSpacing,
		Body: RootVariables.Padding.BodyRatio * RootVariables.RootSpacing,
		Card: RootVariables.Padding.CardRatio * RootVariables.RootSpacing
	},

	Radius: 8,
	RadiusSmall: 4,

	Plots: {
		SingleEyePlotSize: 250
	},

	Data: {
		LabelWidth: 130
	}
};

export default Variables;
