import { createContext, useContext, useState } from 'react';

const AuthActionContext = createContext(undefined);

export const AuthActionProvider = ({ children }) => {
	const [action, setAuthAction] = useState(null);

	const setAction = (action) => setAuthAction(action);

	return <AuthActionContext.Provider value={{ action, setAction }}>{children}</AuthActionContext.Provider>;
};

const useMemoryAuthAction = () => useContext(AuthActionContext);

export const useAuthAction = () => {
	const { action, setAction } = useMemoryAuthAction();
	return { action, setAction };
};
