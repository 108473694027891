import React from 'react';
import { Navigate } from 'react-router-dom';
import Links from '../Links';
import useCurrentRoute from '../../hoc/UseCurrentRoute';
import FullscreenLayout from '../../components/layout/FullscreenLayout';
import { navigationTracker } from '../../analytics/NavigationTracker';
import MainLayout from '../../components/layout/MainLayout';

export const PrivateRoute = ({ auth, fullscreen }) => {
	navigationTracker();
	const route = useCurrentRoute();

	return auth ? (
		fullscreen ? (
			<FullscreenLayout location={route} />
		) : (
			<MainLayout location={route} />
		)
	) : (
		<Navigate to={Links.LOGIN} />
	);
};
