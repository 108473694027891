import React from 'react';
import { ReactComponent as Logo } from '../assets/images/neurosync_logo_text_light.svg';

const AppLoader = () => {
	return (
		<div className='loader-container'>
			<div className='loader-content'>
				<Logo className='logo' />
				<div className='sk-cube-grid'>
					<div className='sk-cube sk-cube1'></div>
					<div className='sk-cube sk-cube2'></div>
					<div className='sk-cube sk-cube3'></div>
					<div className='sk-cube sk-cube4'></div>
					<div className='sk-cube sk-cube5'></div>
					<div className='sk-cube sk-cube6'></div>
					<div className='sk-cube sk-cube7'></div>
					<div className='sk-cube sk-cube8'></div>
					<div className='sk-cube sk-cube9'></div>
				</div>
			</div>
		</div>
	);
};

export default AppLoader;
