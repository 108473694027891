import { useEffect, useState } from 'react';
import AuthService from '../services/AuthService';
import Links from '../navigation/Links';
import { useQueryParams } from './UseCurrentRoute';
import Logger from '../util/Logger';
import { useFocusEffect } from './UseFocusEffect';
import DateUtil from '../util/DateUtil';
import { useNavigate } from 'react-router-dom';

export const useRecoveryFlow = (create) => {
	const params = useQueryParams(['flow']);
	const navigate = useNavigate();
	const [flow, setFlow] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);

		if (params.flow) {
			AuthService.getRecoveryFlow(params.flow)
				.then((flow) => setFlow(flow))
				.catch((error) => {
					Logger.debug(error);
					navigate(Links.LOGIN);
				})
				.finally(() => setLoading(false));
		} else if (!create) {
			setLoading(false);
			navigate(Links.LOGIN);
		} else {
			createRecoveryFlow();
		}
	}, [params.flow]);

	useFocusEffect(() => {
		if (flow && DateUtil.isBefore(flow?.expires_at, Date())) {
			if (create) createRecoveryFlow();
			else navigate(Links.LOGIN);
		}
	}, [flow]);

	const createRecoveryFlow = () => {
		setLoading(true);
		AuthService.createRecoveryFlow()
			.then((flow) => setFlow(flow))
			.catch(Logger.error)
			.finally(() => setLoading(false));
	};

	return { loading, flow };
};
