import * as React from 'react';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Checkbox, FormControlLabel } from '@mui/material';
import { withTranslation } from 'react-i18next';

function ConfirmationDialog({
	open,
	onClose,
	title,
	content,
	positiveText,
	negativeText,
	consentText,
	t,
	onConfirm,
	fullWidth
}) {
	const [consent, setConsent] = useState(false);
	const [_title, setTitle] = useState(title);

	useEffect(() => setConsent(false), [open]);
	useEffect(() => {
		open && setTitle(title);
	}, [open, title]);

	const handleClose = () => onClose();
	const handleConfirm = () => {
		onClose();
		onConfirm();
	};

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			aria-labelledby='alert-dialog-title'
			aria-describedby='alert-dialog-description'
			fullWidth={fullWidth}
		>
			<DialogTitle variant='h4'>{_title}</DialogTitle>
			<DialogContent>
				{content && content()}
				{consentText && (
					<FormControlLabel
						label={consentText}
						sx={{ mt: 1 }}
						control={<Checkbox checked={consent} onChange={(c) => setConsent(c.target.checked)} />}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<Button variant='text' onClick={handleClose}>
					{negativeText || t('cancel')}
				</Button>
				<Button variant='contained' color='error' onClick={handleConfirm} autoFocus disabled={consentText && !consent}>
					{positiveText || t('agree')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default withTranslation()(ConfirmationDialog);
