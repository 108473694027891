export const NavigationEvent = {
	SCREEN_VIEW: 'screen_view'
};

export const AppEvent = {
	LOGIN: 'login',
	VERIFY: 'verify',
	ACCOUNT_VERIFICATION: 'account_verification',

	Login: (identifier, session) => ({
		event: AppEvent.LOGIN,
		data: { identifier, session: session.id, auth_level: session.authenticator_assurance_level }
	}),
	Verify: (identifier, session) => ({
		event: AppEvent.VERIFY,
		data: { identifier, session: session.id, auth_level: session.authenticator_assurance_level }
	}),

	AccountVerified: (identifier) => ({
		event: AppEvent.ACCOUNT_VERIFICATION,
		data: { identifier }
	})
};
