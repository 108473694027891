import { createTheme, responsiveFontSizes } from '@mui/material';
import { typography } from './Typography';
import { ComponentStyles, OverrideStyles } from './Components';
import Colors from './Colors';
import { RootVariables } from './Variables';

export const AppTheme = {
	LIGHT: 'light',
	DARK: 'dark'
};

function create(options) {
	const theme = createTheme(options);
	theme.spacing(1);
	return theme;
}

function colors(Mode, Colors) {
	return {
		primary: {
			main: Colors.Primary,
			light: Colors.PrimaryLight
		},
		secondary: {
			main: Colors.Accent,
			light: Colors.AccentLight
		},
		background: {
			default: Colors.Background,
			paper: Colors.Background,
			light: Colors.PrimaryLight
		},
		text: {
			primary: Colors.Text,
			main: Colors.Text,
			lightest: Colors.TextLight0,
			light: Colors.TextLight1,
			light2: Colors.TextLight2
		},
		error: {
			main: Colors.Error
		},
		success: {
			main: Colors.Success
		},
		light: {
			main: Colors.Light
		},
		dark: {
			main: Colors.Dark
		},
		selection: {
			main: Colors.Selection
		}
	};
}

const BaseTheme = {
	typography,
	shape: {
		borderRadius: 4
	},
	overrides: OverrideStyles,
	components: ComponentStyles,
	spacing: RootVariables.RootSpacing
};

const Light = responsiveFontSizes(
	create({
		...BaseTheme,
		palette: {
			mode: AppTheme.LIGHT,
			...colors(AppTheme.LIGHT, Colors.Light)
		}
	})
);

const Dark = responsiveFontSizes(
	create({
		...BaseTheme,
		palette: {
			mode: AppTheme.DARK,
			...colors(AppTheme.DARK, Colors.Dark)
		}
	})
);

const Theme = { Light, Dark };

export default Theme;
