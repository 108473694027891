import kratos from '../util/kratos';
import KratosUtil from '../util/KratosUtil';

export default class AccountService {
	static createSettingsFlow() {
		return kratos.createBrowserSettingsFlow().then((res) => res.data);
	}

	static getSettingsFlow(id) {
		return kratos.getSettingsFlow({ id }).then((res) => res.data);
	}

	static updateProfile(flow, user) {
		return kratos
			.updateSettingsFlow({
				flow: flow.id,
				updateSettingsFlowBody: {
					method: 'profile',
					traits: user,
					csrf_token: KratosUtil.getCSRFToken(flow)
				}
			})
			.then((res) => res.data);
	}

	static updatePassword(flow, password) {
		return kratos
			.updateSettingsFlow({
				flow: flow.id,
				updateSettingsFlowBody: {
					method: 'password',
					password: password,
					csrf_token: KratosUtil.getCSRFToken(flow)
				}
			})
			.then((res) => res.data);
	}

	static updateOtp(flow, code) {
		return kratos
			.updateSettingsFlow({
				flow: flow.id,
				updateSettingsFlowBody: {
					method: 'totp',
					totp_code: code,
					totp_unlink: !code,
					csrf_token: KratosUtil.getCSRFToken(flow)
				}
			})
			.then((res) => res.data);
	}

	static saveOtpBackupCodes(flow, { enable = true }) {
		return kratos
			.updateSettingsFlow({
				flow: flow.id,
				updateSettingsFlowBody: {
					method: 'lookup_secret',
					lookup_secret_confirm: enable,
					lookup_secret_disable: !enable,
					csrf_token: KratosUtil.getCSRFToken(flow)
				}
			})
			.then((res) => res.data);
	}

	static generateOtpBackupCodes(flow, reveal, regenerate = false) {
		return kratos
			.updateSettingsFlow({
				flow: flow.id,
				updateSettingsFlowBody: {
					method: 'lookup_secret',
					lookup_secret_regenerate: regenerate,
					lookup_secret_reveal: reveal,
					csrf_token: KratosUtil.getCSRFToken(flow)
				}
			})
			.then((res) => res.data);
	}
}
