import kratos from '../util/kratos';
import KratosUtil from '../util/KratosUtil';

export default class AuthService {
	static createLoginFlow(refresh = false) {
		return kratos.createBrowserLoginFlow({ refresh }).then((res) => res.data);
	}

	static createRecoveryFlow() {
		return kratos.createBrowserRecoveryFlow().then((res) => res.data);
	}

	static getLoginFlow(id) {
		return kratos.getLoginFlow({ id }).then((res) => res.data);
	}

	static getRecoveryFlow(id) {
		return kratos.getRecoveryFlow({ id }).then((res) => res.data);
	}

	static getVerificationFlow(id) {
		return kratos.getVerificationFlow({ id }).then((res) => res.data);
	}

	static getSession() {
		return kratos.toSession().then((res) => res.data);
	}

	static updateLoginMethod(flow, level) {
		return kratos.createBrowserLoginFlow({ refresh: false, aal: level }).then((res) => res.data);
	}

	static login(flow, identifier, password) {
		return kratos
			.updateLoginFlow({
				flow: flow.id,
				updateLoginFlowBody: {
					method: 'password',
					identifier: identifier,
					password: password,
					csrf_token: KratosUtil.getCSRFToken(flow)
				}
			})
			.then((res) => res.data);
	}

	static verifyOtp(flow, code) {
		return kratos
			.updateLoginFlow({
				flow: flow.id,
				updateLoginFlowBody: {
					method: 'totp',
					totp_code: code,
					csrf_token: KratosUtil.getCSRFToken(flow)
				}
			})
			.then((res) => res.data.session);
	}

	static verifyOtpBackupCode(flow, code) {
		return kratos
			.updateLoginFlow({
				flow: flow.id,
				updateLoginFlowBody: {
					method: 'lookup_secret',
					lookup_secret: code,
					csrf_token: KratosUtil.getCSRFToken(flow)
				}
			})
			.then((res) => res.data.session);
	}

	static verifyAccountEmail(flow, email, code) {
		return kratos
			.updateVerificationFlow({
				flow: flow.id,
				updateVerificationFlowBody: {
					method: 'code',
					code: code,
					email: email,
					csrf_token: KratosUtil.getCSRFToken(flow)
				}
			})
			.then((res) => res.data);
	}

	static logout() {
		return kratos
			.createBrowserLogoutFlow()
			.then((res) => kratos.updateLogoutFlow({ token: res.data.logout_token, returnTo: res.data.logout_url }));
	}

	static sendRecoverPasswordEmail(flow, email) {
		return kratos
			.updateRecoveryFlow({
				flow: flow.id,
				updateRecoveryFlowBody: {
					method: 'link',
					email: email,
					csrf_token: KratosUtil.getCSRFToken(flow)
				}
			})
			.then((res) => res.data);
	}
}
