import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Page from '../components/Page';
import { withTranslation } from 'react-i18next';
import { useSession } from '../hoc/UseSession';
import {
	Box,
	Card,
	CardContent,
	Container,
	List,
	ListItemButton,
	ListItemText,
	Stack,
	Typography
} from '@mui/material';
import Variables from '../assets/styles/Variables';
import Divider from '@mui/material/Divider';
import TextField from '../components/form/TextField';
import { LoadingButton } from '@mui/lab';
import Form from '../components/form/Form';
import useFormValidation from '../hoc/UseFormValidation';
import * as Yup from 'yup';
import TextFieldPassword from '../components/form/TextFieldPassword';
import FormValidations from '../util/FormValidations';
import { useSettingsFlow } from '../hoc/UseSettingsFlow';
import { LinearLoader } from '../components/Loader';
import Notifications from '../util/Notifications';
import Logger from '../util/Logger';
import AccountService from '../services/AccountService';
import {
	ClearOutlined,
	FingerprintOutlined,
	KeyOutlined,
	ReplayOutlined,
	SettingsOutlined,
	VisibilityOutlined
} from '@mui/icons-material';
import { useAuthAction } from '../hoc/UseAuthAction';
import AuthAction from '../data/enums/AuthAction';
import KratosUtil from '../util/KratosUtil';
import { useNavigate } from 'react-router-dom';
import ConfirmationDialog from '../components/ConfirmationDialog';
import Links from '../navigation/Links';
import Text from '../assets/styles/Typography';
import { Features } from '../util/Constants';

const SectionRefs = {
	Profile: 'profile',
	Password: 'password',
	TwoFactor: 'two-factor-authentication'
};

const AccountSection = ({ id, title, description, ...props }) => (
	<Card sx={{ width: '100%', height: 'fit-content' }} id={id}>
		<CardContent style={{ display: 'flex', flexDirection: 'column' }}>
			<Typography variant='h5' mb={description ? 0 : 2}>
				{title}
			</Typography>
			{description && (
				<Typography variant='body2' mb={2}>
					{description}
				</Typography>
			)}
			{props.children}
		</CardContent>
	</Card>
);

const AccountPage = ({ t }) => {
	const navigate = useNavigate();
	const { user, refreshSession } = useSession();
	const { action, setAction } = useAuthAction();
	const [tempAction, setTempAction] = useState();
	const { flow, loading, groups, setFlow } = useSettingsFlow(true);

	const setAuthAction = (type, data) => setTempAction({ type, data });
	const onAuthActionConfirmed = () => {
		setAction(tempAction);
		setTempAction(null);
		navigate(Links.LOGIN);
	};

	const onRequestCompleted = useCallback((flow, message = null, refresh = false) => {
		if (message) Notifications.success(message);
		setFlow(flow);
		if (refresh) refreshSession();
	}, []);

	const SectionLabels = useCallback(
		() => (
			<Box maxWidth={250} position='fixed' display={{ xs: 'none', md: 'block' }}>
				<List component='nav' aria-label='secondary mailbox folder'>
					<ListItemButton component='a' href={`#${SectionRefs.Profile}`}>
						<SettingsOutlined style={{ marginRight: 10 }} />
						<ListItemText primary={<Typography variant='body2'>{t('section_profile')}</Typography>} />
					</ListItemButton>
					<ListItemButton component='a' href={`#${SectionRefs.Password}`}>
						<KeyOutlined style={{ marginRight: 10 }} />
						<ListItemText primary={<Typography variant='body2'>{t('section_password')}</Typography>} />
					</ListItemButton>
					<ListItemButton component='a' href={`#${SectionRefs.TwoFactor}`}>
						<FingerprintOutlined style={{ marginRight: 10 }} />
						<ListItemText primary={<Typography variant='body2'>{t('section_two_factor_auth')}</Typography>} />
					</ListItemButton>
				</List>
			</Box>
		),
		[]
	);

	const BasicProfileSection = useCallback(({ flow, user, action }) => {
		const [loading, setLoading] = useState(false);

		const { register, errors, handleSubmit } = useFormValidation({
			mode: 'onSubmit',
			initialValues: { ...user, ...action?.data },
			schema: Yup.object({
				email: Yup.string().email(t('error_email_invalid')).required(t('error_email_required')),
				name: Yup.object({
					first: Yup.string().required(t('error_name_required')),
					last: Yup.string()
				})
			})
		});

		useEffect(() => {
			if (action?.type === AuthAction.CHANGE_DETAILS) {
				onSubmit(action.data);
			}
		}, [action]);

		const onSubmit = (data) => {
			setAction(null);
			setLoading(true);

			AccountService.updateProfile(flow, data)
				.then((flow) => onRequestCompleted(flow, t('msg_profile_update_success'), true))
				.catch((err) => {
					if (KratosUtil.handleAuthError(err)) {
						return setAuthAction(AuthAction.CHANGE_DETAILS, data);
					} else {
						Logger.debug(err);
						Notifications.error(t('error_profile_update_failed'));
					}
				})
				.finally(() => setLoading(false));
		};

		return (
			<AccountSection title={t('section_profile')} id={SectionRefs.Profile}>
				<Form onSubmit={handleSubmit(onSubmit)} data-testid='loginForm' style={{ width: '100%' }}>
					<TextField {...register('name.first')} error={errors} fullWidth label={t('label_first_name')} />
					<TextField {...register('name.last')} error={errors} fullWidth label={t('label_last_name')} />
					<TextField {...register('email')} error={errors} fullWidth disabled label={t('label_email')} />

					<LoadingButton sx={{ mt: 1 }} size='large' type='submit' loading={loading} style={{ float: 'right' }}>
						{t('save')}
					</LoadingButton>
				</Form>
			</AccountSection>
		);
	}, []);

	const PasswordSection = useCallback(({ flow, action }) => {
		const [loading, setLoading] = useState(false);
		const [validationErrors, setValidationErrors] = useState(null);

		const { register, control, errors, handleSubmit } = useFormValidation({
			mode: 'onSubmit',
			validationErrors,
			initialValues: { password: action?.data?.password, confirmPassword: action?.data?.password },
			schema: Yup.object({
				password: FormValidations.passwordValidator(t),
				confirmPassword: Yup.lazy(() => {
					return Yup.string()
						.required(t('error_password_not_matching'))
						.test(
							'passwords-match',
							t('error_password_not_matching'),
							(value) => control._formValues.password === value
						);
				})
			})
		});

		useEffect(() => {
			if (action?.type === AuthAction.CHANGE_PASSWORD) {
				onSubmit(action.data);
			}
		}, [action]);

		const onSubmit = ({ password }) => {
			setAction(null);
			setLoading(true);

			AccountService.updatePassword(flow, password)
				.then((flow) => onRequestCompleted(flow, t('msg_password_update_success')))
				.catch((err) => {
					if (KratosUtil.handleAuthError(err)) {
						return setAuthAction(AuthAction.CHANGE_PASSWORD, { password });
					} else {
						const passError = err.response.data?.ui?.nodes?.find(
							(it) => it.attributes.name === 'password' && it.type === 'input'
						)?.messages;
						if (passError?.length) setValidationErrors([{ field: 'password', message: passError[0].text }]);
						else Logger.debug(err);

						Notifications.error(t('error_password_update_failed'));
					}
				})
				.finally(() => setLoading(false));
		};

		return (
			<AccountSection title={t('section_password')} description={t('section_password_info')} id={SectionRefs.Password}>
				<Form onSubmit={handleSubmit(onSubmit)} data-testid='passwordForm' style={{ width: '100%' }}>
					<TextFieldPassword
						{...register('password')}
						error={errors}
						fullWidth
						newPassword
						label={t('label_password')}
					/>
					<TextFieldPassword
						{...register('confirmPassword')}
						error={errors}
						fullWidth
						newPassword
						label={t('label_confirm_password')}
					/>

					<LoadingButton sx={{ mt: 1 }} size='large' type='submit' loading={loading} style={{ float: 'right' }}>
						{t('save')}
					</LoadingButton>
				</Form>
			</AccountSection>
		);
	}, []);

	const TwoFactorSection = useCallback(({ flow, groups, action }) => {
		const [loading, setLoading] = useState(false);
		const [loadingCodes, setLoadingCodes] = useState(false);
		const [validationErrors, setValidationErrors] = useState(null);
		const secret = useMemo(
			() => groups.totp.find((it) => it.attributes.id === 'totp_secret_key')?.attributes?.text?.context?.secret,
			[groups]
		);
		const qrcode = useMemo(() => groups.totp.find((it) => it.attributes.id === 'totp_qr'), [groups]);
		const backupCodes = useMemo(
			() =>
				groups.lookup_secret
					?.find((it) => it.attributes.id === 'lookup_secret_codes')
					?.attributes?.text?.text?.replaceAll(', ', ' ')
					?.replaceAll('used', ''),
			[groups]
		);
		const backupCodesSaved = useMemo(
			() => !!groups.lookup_secret?.find((it) => it.attributes.name === 'lookup_secret_disable'),
			[groups]
		);
		const revealBackupCodes = useMemo(
			() => !!groups.lookup_secret?.find((it) => it.attributes.name === 'lookup_secret_reveal'),
			[groups]
		);
		const generateBackupCodes = useMemo(
			() => !!groups.lookup_secret?.find((it) => it.attributes.name === 'lookup_secret_regenerate'),
			[groups]
		);

		const { register, errors, handleSubmit } = useFormValidation({
			mode: 'onSubmit',
			validationErrors,
			schema: Yup.object({
				code: Yup.string().required(t('error_otp_required'))
			})
		});

		useEffect(() => {
			if (action?.type === AuthAction.UPDATE_TOTP) {
				onSubmit(action.data);
			} else if (
				action?.type === AuthAction.GENERATE_TOTP_BACKUP_CODES ||
				action?.type === AuthAction.UPDATE_TOTP_BACKUP_CODES
			) {
				setAction(null);
			}
		}, [action]);

		const onSubmit = ({ code }) => {
			setAction(null);
			setLoading(true);

			AccountService.updateOtp(flow, code)
				.then((flow) => on2FAUpdated(flow, !!code))
				.catch((err) => {
					if (KratosUtil.handleAuthError(err)) {
						return setAuthAction(AuthAction.UPDATE_TOTP, { code });
					} else {
						const otpError = err.response.data?.ui?.nodes?.find(
							(it) => it.attributes.name === 'totp_code' && it.group === 'totp'
						)?.messages;
						if (otpError?.length) setValidationErrors([{ field: 'code', message: otpError[0].text }]);
						else Logger.debug(err);

						Notifications.error(t('error_otp_update_failed'));
					}
				})
				.finally(() => setLoading(false));
		};

		const on2FAUpdated = (flow, enabled) => {
			if (enabled) generateRecoveryCodes(flow);

			onRequestCompleted(flow, t('msg_otp_update_success'));
		};

		const generateRecoveryCodes = (flow, reveal = false) => {
			setLoadingCodes(true);

			AccountService.generateOtpBackupCodes(flow, reveal, !reveal)
				.then((flow) => onRequestCompleted(flow))
				.catch((e) => {
					if (KratosUtil.handleAuthError(e)) {
						return setAuthAction(AuthAction.GENERATE_TOTP_BACKUP_CODES);
					} else {
						Logger.error(e);
						Notifications.error(t('error_totp_recovery_codes_failed'));
					}
				})
				.finally(() => setLoadingCodes(false));
		};

		const saveRecoveryCodes = (flow, enable = true) => {
			setLoadingCodes(true);

			AccountService.saveOtpBackupCodes(flow, { enable })
				.then((flow) => onRequestCompleted(flow))
				.catch((e) => {
					if (KratosUtil.handleAuthError(e)) {
						return setAuthAction(AuthAction.GENERATE_TOTP_BACKUP_CODES);
					} else {
						Logger.error(e);
						Notifications.error(t('error_totp_recovery_codes_save_failed'));
					}
				})
				.finally(() => setLoadingCodes(false));
		};

		return (
			<AccountSection
				id={SectionRefs.TwoFactor}
				title={t('section_two_factor_auth')}
				description={secret && t('section_two_factor_info')}
			>
				{secret ? (
					<>
						<img
							src={qrcode?.attributes?.src}
							alt='QR Code'
							style={{ height: qrcode?.attributes?.height, width: qrcode?.attributes?.width }}
						/>

						<Typography variant='body2'>{t('otp_secret_description')}</Typography>
						<Typography {...Text.Code} mt={1}>
							{secret}
						</Typography>

						<Form onSubmit={handleSubmit(onSubmit)} data-testid='loginForm' style={{ width: '100%' }} mt={1.5}>
							<TextField {...register('code')} error={errors} fullWidth label={t('label_totp')} />

							<LoadingButton sx={{ mt: 1 }} size='large' type='submit' loading={loading} style={{ float: 'right' }}>
								{t('verify')}
							</LoadingButton>
						</Form>
					</>
				) : (
					<Box display='flex' justifyContent='space-between' alignItems='center'>
						<Typography variant='body2' mr={2}>
							{t('section_two_factor_info_unlink')}
						</Typography>
						<LoadingButton
							size='large'
							loading={loading}
							onClick={() => onSubmit({ code: null })}
							style={{ width: 'fit-content', alignSelf: 'flex-end' }}
						>
							{t('unlink')}
						</LoadingButton>
					</Box>
				)}

				{(secret ? backupCodesSaved : groups.lookup_secret) && (
					<>
						<Typography variant='h6' mt={2}>
							{t('totp_recovery_codes')}
						</Typography>
						<Typography variant='body2'>{t('totp_recovery_codes_description')}</Typography>
						{backupCodes && (
							<>
								<Typography {...Text.Code} my={1}>
									{backupCodes}
								</Typography>

								{!backupCodesSaved && (
									<LoadingButton
										size='medium'
										variant='outlined'
										loading={loadingCodes}
										onClick={() => saveRecoveryCodes(flow, true)}
										style={{ width: 'fit-content' }}
									>
										{t('save_totp_backup_codes')}
									</LoadingButton>
								)}
							</>
						)}

						<Stack direction='row' spacing={0.5} mt={1} ml={-1}>
							{revealBackupCodes && (
								<LoadingButton
									size='small'
									variant='text'
									loading={loadingCodes}
									startIcon={<VisibilityOutlined />}
									onClick={() => generateRecoveryCodes(flow, true)}
									sx={{ width: 'fit-content' }}
								>
									{t('reveal_totp_backup_codes')}
								</LoadingButton>
							)}

							{Features.REMOVE_MFA && backupCodesSaved && (
								<LoadingButton
									size='small'
									variant='text'
									loading={loadingCodes}
									startIcon={<ClearOutlined />}
									onClick={() => saveRecoveryCodes(flow, false)}
									sx={{ width: 'fit-content', ml: -1 }}
								>
									{t('remove_totp_backup_codes')}
								</LoadingButton>
							)}

							{((backupCodesSaved && !revealBackupCodes) || generateBackupCodes) && (
								<LoadingButton
									size='small'
									variant='text'
									loading={loadingCodes}
									startIcon={<ReplayOutlined />}
									onClick={() => generateRecoveryCodes(flow, false)}
									sx={{ width: 'fit-content', ml: -1 }}
								>
									{t('generate_new_totp_backup_codes')}
								</LoadingButton>
							)}
						</Stack>
					</>
				)}
			</AccountSection>
		);
	}, []);

	return (
		<Page meta={{ title: t('account_settings') }} sx={{ p: 0 }}>
			<Box
				sx={{
					zIndex: 2,
					width: '100%',
					position: 'fixed',
					backgroundColor: 'background.light'
				}}
			>
				<Container maxWidth='lg'>
					<Typography variant='h3' pb={2} pt={Variables.Padding.BodyRatio + 2}>
						{t('account_settings')}
					</Typography>
				</Container>

				<Divider />
				{loading && <LinearLoader />}
			</Box>

			{!loading && (
				<Container maxWidth='lg'>
					<Box display='flex' flexDirection='row' mt={Variables.Padding.BodyRatio + 5 + 2}>
						<SectionLabels />

						<Stack
							direction='column'
							spacing={Variables.Padding.BodyRatio}
							flex={1}
							pl={{ xs: 0, md: 3 }}
							mt={1}
							mb={Variables.Padding.BodyRatio}
							ml={{ xs: 0, md: '250px' }}
						>
							<BasicProfileSection flow={flow} action={action} user={user} />
							{groups?.password && <PasswordSection flow={flow} action={action} />}
							{groups?.totp && <TwoFactorSection flow={flow} action={action} groups={groups} />}
						</Stack>
					</Box>
				</Container>
			)}

			<ConfirmationDialog
				fullWidth
				open={!!tempAction}
				onClose={() => setTempAction(null)}
				onConfirm={onAuthActionConfirmed}
				positiveText={t('continue')}
				title={t('verify_yourself')}
				content={() => t('verify_yourself_description')}
			/>
		</Page>
	);
};

export default withTranslation()(AccountPage);
