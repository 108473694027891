import MenuItem from '@mui/material/MenuItem';
import Dropdown from './Dropdown';
import Colors from '../assets/styles/Colors';
import { ReactComponent as AccountIcon } from '../assets/icons/account.svg';
import Button from '@mui/material/Button';
import { Box, IconButton, Typography } from '@mui/material';
import { DEFAULT_FONT_WEIGHT } from '../assets/styles/Typography';
import Divider from '@mui/material/Divider';
import { useSession } from '../hoc/UseSession';
import AuthService from '../services/AuthService';
import Logger from '../util/Logger';
import Notifications from '../util/Notifications';

const ProfileMenu = ({ t }) => {
	const { user, clearSession } = useSession();

	const name = `${user?.name?.first || ''} ${user?.name?.last || ''}`;

	const onLogoutClick = () => {
		AuthService.logout()
			.then(() => {
				clearSession();
				window.location.reload();
			})
			.catch((error) => {
				Logger.error(error);
				Notifications.info(t('error_logout_failed'));
			});
	};

	return (
		<Dropdown
			menuItems={() => (
				<div>
					<Box pt={0.5} pl={1} pr={1} pb={1}>
						{user?.name && <Typography>{name}</Typography>}
						<Typography color='text.light2' variant='body2'>
							{user?.email}
						</Typography>

						<Typography color='text.lightest' variant='body2'>
							Version: {process.env.REACT_APP_VERSION}
						</Typography>
					</Box>

					<Divider />
					<MenuItem onClick={onLogoutClick} sx={{ color: Colors.Red }}>
						{t('logout')}
					</MenuItem>
				</div>
			)}
			item={(props) =>
				user?.name ? (
					<Button
						endIcon={<AccountIcon height={28} width={28} />}
						variant='text'
						color='text'
						sx={(theme) => ({
							mr: -1,
							fontWeight: DEFAULT_FONT_WEIGHT,
							fontSize: '1rem',
							'& svg': { fill: theme.palette.text.primary }
						})}
						{...props}
					>
						{name}
					</Button>
				) : (
					<IconButton {...props}>
						<AccountIcon height={28} width={28} />
					</IconButton>
				)
			}
		/>
	);
};

export default ProfileMenu;
