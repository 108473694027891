import Firebase from '../data/Firebase';
import { getAnalytics, logEvent, setUserId, setUserProperties } from 'firebase/analytics';
import Constants from '../util/Constants';
import { NavigationEvent } from './TrackingEvent';
import Logger from '../util/Logger';

const ENABLED = Constants.IS_PRODUCTION || Constants.IS_TEST;
const LOGGING = !ENABLED;

const analytics = getAnalytics(Firebase);

export default class Analytics {
	static setUser(user) {
		const properties = {
			app_type: 'portal',
			email: user.email,
			version: process.env.REACT_APP_VERSION
		};

		debug('user_properties', properties);

		if (ENABLED) {
			setUserId(analytics, user.id);
			setUserProperties(analytics, properties);
		}
	}

	static reset() {
		setUserId(analytics, undefined);
		setUserProperties(analytics, undefined);
		debug('user_properties_reset');
	}

	static screenView(screen, clazz = undefined) {
		const event = { firebase_screen: screen };
		if (clazz) event.firebase_screen_class = clazz;

		if (ENABLED) Analytics.log(NavigationEvent.SCREEN_VIEW, event);
		debug(NavigationEvent.SCREEN_VIEW, event);
	}

	static log(event, data) {
		if (typeof event !== 'string') {
			data = event.data;
			event = event.event;
		}

		if (ENABLED) logEvent(analytics, event, data);
		debug(event, data);
	}
}

function debug(event, params = undefined) {
	if (!LOGGING) return;
	return Logger.debug(`%c${event}`, '🐦;background: lightblue; color: #444; padding: 2px; border-radius: 5px;', params);
}
