import * as Yup from 'yup';

const FormValidations = {
	passwordValidator: (t, requiredError = t('error_password_required')) =>
		Yup.string()
			.required(requiredError)
			.max(16, t('error_password_less_than_16'))
			.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, t('password_description'))
};

export default FormValidations;
