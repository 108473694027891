import { useEffect, useState } from 'react';
import AuthService from '../services/AuthService';
import Links from '../navigation/Links';
import { useNavigate } from 'react-router-dom';
import { useQueryParams } from './UseCurrentRoute';
import Logger from '../util/Logger';
import { useFocusEffect } from './UseFocusEffect';
import DateUtil from '../util/DateUtil';
import KratosUtil from '../util/KratosUtil';

export const useVerificationFlow = () => {
	const params = useQueryParams(['flow']);
	const navigate = useNavigate();
	const [flow, setFlow] = useState();
	const [groups, setGroups] = useState();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);

		if (params.flow) {
			AuthService.getVerificationFlow(params.flow)
				.then(handleFlow)
				.catch((error) => {
					Logger.debug(error);
					navigate(Links.LOGIN);
				})
				.finally(() => setLoading(false));
		} else {
			setLoading(false);
			navigate(Links.LOGIN);
		}
	}, [params.flow]);

	useFocusEffect(() => {
		if (flow && DateUtil.isBefore(flow?.expires_at, Date())) {
			navigate(Links.LOGIN);
		}
	}, [flow]);

	const handleFlow = (flow) => {
		setFlow(flow);
		setGroups(KratosUtil.createUIGroups(flow));
	};

	return { loading, flow, groups };
};
