/* eslint-disable no-console */
import Constants from './Constants';

export default class Logger {
	static debug(...args) {
		if (!Constants.IS_PRODUCTION) console.log(...args);
	}

	static info = console.info;

	static error = console.error;

	static warn = console.warn;

	static clear = console.clear;
}
