import { useLocation, useParams } from 'react-router-dom';
import Analytics from './Analytics';
import { useEffect } from 'react';

export const generateGenericRoutePath = (location, params) => {
	const paramKeys = Object.keys(params);
	return location.pathname.replace(/\/([^/]+)/g, (match, paramValue) => {
		let paramName = paramKeys.find((name) => params[name] === paramValue);
		if (
			(!paramName || paramName === '*') &&
			/^[0-9a-z]{8}-?[0-9a-z]{4}-?[1-5][0-9a-z]{3}-?[89ab][0-9a-z]{3}-?[0-9a-z]{12}$/i.test(paramValue)
		)
			paramName = 'id';
		return paramName ? `/:${paramName === '*' ? paramValue : paramName}` : match;
	});
};

export const navigationTracker = () => {
	const location = useLocation();
	const params = useParams();

	useEffect(() => {
		const id = setTimeout(() => {
			Analytics.screenView(generateGenericRoutePath(location, params));
		}, 200);
		return () => clearTimeout(id);
	}, [location, params]);

	return null;
};
