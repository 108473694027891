import Variables from './Variables';

export const DEFAULT_FONT_WEIGHT = 500;

export const typography = {
	fontFamily: Variables.Font.Body,
	body1: {
		fontWeight: DEFAULT_FONT_WEIGHT
	},
	body2: {
		fontWeight: DEFAULT_FONT_WEIGHT
	},
	h6: {
		fontFamily: Variables.Font.Heading,
		fontWeight: 700,
		fontSize: '1rem'
	},
	h5: {
		fontFamily: Variables.Font.Heading,
		fontWeight: 700,
		fontSize: '1.125rem'
	},
	h4: {
		fontFamily: Variables.Font.Heading,
		fontWeight: 700,
		fontSize: '1.5rem'
	},
	h3: {
		fontFamily: Variables.Font.Heading,
		fontWeight: 700,
		fontSize: '1.75rem'
	},
	h2: {
		fontFamily: Variables.Font.Heading,
		fontWeight: 600,
		fontSize: '2rem'
	},
	h1: {
		fontFamily: Variables.Font.Heading,
		fontWeight: 600,
		fontSize: '2.5rem'
	},
	subtitle1: {
		fontWeight: DEFAULT_FONT_WEIGHT
	},
	subtitle2: {
		fontWeight: DEFAULT_FONT_WEIGHT
	},
	button: {
		fontWeight: 600,
		fontSizeSmall: '0.725rem',
		fontSizeMedium: '0.95rem',
		fontSizeLarge: '1rem',
		textTransform: 'capitalize'
	},
	input: {
		fontWeight: DEFAULT_FONT_WEIGHT,
		fontSize: '1rem'
	},
	caption: {
		fontWeight: 600
	}
};

const Text = {
	Label: { variant: 'subtitle1' },
	Subtext: { fontSize: '0.9rem', color: 'text.light' },

	MessageTitle: { variant: 'h5', className: 'text-heading-small', fontWeight: 600 },
	Message: { variant: 'body1', fontWeight: 600 },

	Code: {
		fontFamily: 'monospace',
		variant: 'body2',
		fontWeight: 600,
		letterSpacing: '1px',
		lineHeight: 2
	},

	DialogTitle: {
		fontSize: '1.5rem',
		lineHeight: '1.4rem',
		fontWeight: 700
	},

	TableHeader: { fontWeight: 600 }
};

export default Text;
