import { Backdrop, CircularProgress, LinearProgress } from '@mui/material';
import * as React from 'react';

export const CircularLoader = ({ style }) => (
	<div
		style={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			minHeight: 'inherit',
			height: 'inherit',
			...style
		}}
	>
		<CircularProgress color='secondary' />
	</div>
);

export const LinearLoader = ({ style }) => (
	<div
		style={{
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center',
			width: 'inherit',
			...style
		}}
	>
		<LinearProgress style={{ width: '100%' }} color='secondary' />
	</div>
);

export const OverlayLoader = ({ style, fullscreen }) => (
	<Backdrop
		open={true}
		sx={{
			color: 'white',
			zIndex: (theme) => theme.zIndex.drawer + 1,
			width: '100%',
			height: '100%',
			position: fullscreen ? 'fixed' : 'absolute',
			...style
		}}
	>
		<CircularProgress color='inherit' />
	</Backdrop>
);
