import Util from '../util/Util';

const Links = {
	LOGIN: '/login',
	ACCOUNT: '/account',
	LOGOUT: '/logout',
	VERIFICATION: '/verification',
	RECOVERY: '/recovery',

	Login: {
		VERIFY: 'verify',
		BACKUP_CODE: 'backup',
		FORGOT_PASSWORD: 'forgot-password',

		Otp: (identity, flow) => `${Links.Login.VERIFY}?${Util.queryString({ flow: flow.id, identity })}`,
		BackupCode: (identity, flow) =>
			`${Links.Login.VERIFY}/${Links.Login.BACKUP_CODE}?${Util.queryString({ flow: flow.id, identity })}`
	},

	Recover: {
		RESET_PASSWORD: 'reset-password'
	},

	/**
	 * Nested Links
	 * Use this function to use base urls as nested links .e.g in Institution/Group page
	 */
	N: (link) => (link[0] === '/' ? link.substring(1) : link)
};

export default Links;
