import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import TextField from './TextField';

const TextFieldPassword = ({ innerRef, newPassword, ...props }) => {
	const [showPassword, setShowPassword] = useState(false);

	const onTogglePasswordVisibility = () => {
		setShowPassword((prevMode) => !prevMode);
	};

	return (
		<TextField
			{...props}
			ref={innerRef}
			type={showPassword ? 'text' : 'password'}
			InputProps={{
				endAdornment: (
					<InputAdornment position='end'>
						<IconButton onClick={onTogglePasswordVisibility} onMouseDown={(event) => event.preventDefault()}>
							{showPassword ? <VisibilityOff /> : <Visibility />}
						</IconButton>
					</InputAdornment>
				),
				autoComplete: newPassword ? 'new-password' : 'password',
				form: {
					autocomplete: newPassword ? 'off' : 'on'
				}
			}}
		/>
	);
};

export default React.forwardRef((props, ref) => <TextFieldPassword {...props} innerRef={ref} />);
