import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Links from './Links';
import { PublicRoute } from './components/PublicRoute';
import { PrivateRoute } from './components/PrivateRoute';
import LoginPage from '../pages/auth/LoginPage';
import VerifyPage from '../pages/auth/VerifyPage';
import AccountPage from '../pages/AccountPage';
import ForgotPasswordPage from '../pages/ForgotPasswordPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';
import gsap from 'gsap';
import AccountVerificationPage from '../pages/AccountVerificationPage';
import Redirect from './components/Redirect';

export default function NavigationRoutes({ session }) {
	const [firstRender, setFirstRender] = useState(true);
	const location = useLocation();

	useEffect(() => {
		if (!firstRender) gsap.fromTo('.page-root', { opacity: 0, x: 20 }, { opacity: 1, x: 0 });
		setFirstRender(false);
	}, [location?.pathname]);

	return (
		<Routes>
			<Route path={Links.LOGIN} element={<PublicRoute auth={!!session} />}>
				<Route index element={<LoginPage />} />
				<Route path={Links.Login.VERIFY}>
					<Route index element={<VerifyPage />} />
					<Route path={Links.Login.BACKUP_CODE} element={<VerifyPage backup />} />
				</Route>
				<Route path={Links.Login.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
				<Route path='*' exact element={<Redirect to={Links.LOGIN} replace />} />
			</Route>

			<Route path={Links.RECOVERY} element={<PublicRoute auth={!!session} />}>
				<Route index element={<Redirect to={Links.Recover.RESET_PASSWORD} params={['flow']} />} />
				<Route path={Links.Recover.RESET_PASSWORD} element={<ResetPasswordPage />} />
			</Route>

			<Route path={Links.VERIFICATION} element={<PrivateRoute auth={!!session} fullscreen />}>
				<Route index element={<AccountVerificationPage />} />
			</Route>

			<Route path={Links.ACCOUNT} element={<PrivateRoute auth={!!session} />}>
				<Route index element={<AccountPage />} />
			</Route>

			<Route path='*' exact element={<Redirect to={Links.LOGIN} />} />
		</Routes>
	);
}
