import { createContext, useContext, useEffect, useState } from 'react';
import AuthService from '../services/AuthService';

const SessionContext = createContext(undefined);

export const SessionProvider = ({ children }) => {
	const [session, setMemorySession] = useState(null);

	const setSession = (session) => setMemorySession(session);
	const clearSession = () => setMemorySession(null);

	return <SessionContext.Provider value={{ session, setSession, clearSession }}>{children}</SessionContext.Provider>;
};

const useMemorySession = () => useContext(SessionContext);

export const useSession = (cache = true) => {
	const [loading, setLoading] = useState(!cache);
	const { session, setSession, clearSession } = useMemorySession();

	if (!cache) {
		useEffect(() => {
			fetchSession();
		}, []);
	}

	const fetchSession = () => {
		setLoading(true);
		AuthService.getSession()
			.then((session) => setSession(session))
			.catch(() => setSession(null))
			.finally(() => setLoading(false));
	};

	return { loading, session, user: session?.identity?.traits, setSession, clearSession, refreshSession: fetchSession };
};
