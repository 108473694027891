import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrBefore);

const DateUtil = {
	FORMAT_DATE: 'MMM D, YYYY',
	FORMAT_DATE_TIME: 'MMM D, YYYY - h:mm a',

	today: () => dayjs(),
	format: (date, format = DateUtil.FORMAT_DATE) => (date ? dayjs(date).format(format) : ''),
	parse: (date, format) => {
		if (!date) return null;
		const parsedDate = dayjs(date, format);
		if (!parsedDate.isValid()) {
			throw new Error(`Invalid date: ${date} with format: ${format}`);
		}
		return parsedDate.toDate();
	},
	isBefore: (one, two, unit = 'milliseconds') => dayjs(one).isBefore(dayjs(two), unit),
	isSameOrAfter: (one, two, unit = 'milliseconds') => dayjs(one).isSameOrAfter(dayjs(two), unit),

	millisToSeconds: (milliseconds) => milliseconds / 1000,
	millis: (date) => dayjs(date).valueOf()
};

export default DateUtil;
