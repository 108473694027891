import { CssBaseline, ThemeProvider } from '@mui/material';
import AppLoader from './components/AppLoader';
import { Toaster } from 'react-hot-toast';
import NavigationRoutes from './navigation/routes';
import Theme, { AppTheme } from './assets/styles/Theme';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { SessionProvider, useSession } from './hoc/UseSession';
import { AuthActionProvider } from './hoc/UseAuthAction';

const App = () => {
	const { session, loading: showAppLoader } = useSession(false);

	return (
		<>
			<CssBaseline enableColorScheme />
			<Helmet>
				<meta name='client-version' content={process.env.REACT_APP_VERSION} />
			</Helmet>
			<div className='flex flex-grow' style={{ minWidth: 0 }} data-testid='app-layout'>
				<Toaster position='bottom-center' />
				{showAppLoader ? <AppLoader /> : <NavigationRoutes session={session} />}
			</div>
		</>
	);
};

const ThemedApp = () => {
	const theme = AppTheme.LIGHT;

	return (
		<ThemeProvider theme={theme === AppTheme.DARK ? Theme.Dark : Theme.Light}>
			<BrowserRouter>
				<SessionProvider>
					<AuthActionProvider>
						<App />
					</AuthActionProvider>
				</SessionProvider>
			</BrowserRouter>
		</ThemeProvider>
	);
};

export const NeurosyncApp = () => <ThemedApp />;
