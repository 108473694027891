import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Locale from './Locale';
import detector from 'i18next-browser-languagedetector';

import translationsENG from './translations/en/translations.json';

const resources = {
	[Locale.ENGLISH]: {
		translation: translationsENG
	}
};

i18n
	.use(detector)
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: Locale.ENGLISH,
		fallbackLng: Locale.ENGLISH, // use en if detected lng is not available
		keySeparator: false, // we do not use keys in forms messages.welcome
		interpolation: {
			escapeValue: false // react already safes from xss
		},
		react: {
			useSuspense: true
		}
	});

export default i18n;
